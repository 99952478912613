<a class="text-body" [routerLink]="['/certification', 'join']">
  <div class="bg-white border rounded-3">
    <div class="row g-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-right-to-bracket fa-3x text-tertiary p-4 align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            <b>{{ 'certification.Rejoindre une session de certification ou de coaching' | translate }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
